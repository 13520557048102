import IApiModelHierarchyCountry from 'common/models/hierarchies/api-hierarchy-country';
import AbstractModelHierarchy from 'common/models/hierarchies/abstract-hierarchy';
import ModelHierarchyStore from 'common/models/hierarchies/ModelHierarchyStore';
import ModelTypeTreeLevel from 'common/models/enums/models/type-tree-level';
import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import CallbackString from 'common/models/types/callback-string';

export default class ModelHierarchyCountry extends AbstractModelHierarchy {
  declare id: string;

  public readonly isDigitalValid: boolean;

  public readonly isPhysicalValid: boolean;

  constructor(
    code: string,
    type: ModelTypeTreeLevel = TypeTreeLevel.PAYS,
    label?: string | CallbackString,
    isDigitalValid = false,
    isPhysicalValid = false,
  ) {
    super(code, type, label);

    this.id = code;

    this.isDigitalValid = !!isDigitalValid;
    this.isPhysicalValid = !!isPhysicalValid;
  }

  protected get undefinedLabel() {
    return this.id;
  }

  public static fromApiModelHierarchy(
    data: IApiModelHierarchyCountry,
  ): ModelHierarchyCountry {
    return new ModelHierarchyCountry(
      data.code,
      TypeTreeLevel.byValue(data.type),
      data.label,
      data.digital_valid,
      data.physical_valid,
    );
  }

  public isParentOf(hierarchy: ModelHierarchyStore): boolean {
    return hierarchy && this.isEqual(hierarchy.parent);
  }
}

export const DEFAULT_SELECTOR_CODE_COUNTRY = 'FR';

export const DEFAULT_SELECTOR_HIERARCHY_COUNTRY = new ModelHierarchyCountry(
  DEFAULT_SELECTOR_CODE_COUNTRY,
  TypeTreeLevel.PAYS,
);
