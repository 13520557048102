import React from 'react';
import { Button } from 'antd';

interface ButtonCustomProps {
  readonly id: any;
  readonly className: any;
  readonly danger: boolean;
  readonly disabled: boolean;
  readonly icon: any;
  readonly ghost: boolean;
  readonly loading: boolean;
  readonly size: any;
  readonly shape: any;
  readonly style: any;
  readonly title: string;
  readonly type: any;
  readonly onClick: () => void;
}

function ButtonCustom({
  id = null,
  className = null,
  danger = false,
  disabled = false, // boolean
  icon = null,
  ghost = false, // boolean
  loading = false,
  size = 'middle', // large | middle | small
  shape = null, // round | circle
  style = {},
  title = null, // string
  type = null, // primary | ghost | dashed | danger | link | text | or nothing (default)
  onClick = null, // callback
}: ButtonCustomProps) {
  return (
    <Button
      id={id || title}
      className={className}
      danger={danger}
      disabled={disabled}
      icon={icon}
      ghost={ghost}
      loading={loading}
      onClick={onClick}
      size={size}
      shape={shape}
      style={style}
      type={type}
    >
      {title}
    </Button>
  );
}

export default ButtonCustom;
