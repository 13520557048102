export const SALES_SUPPORT_FILTER_SECTION_NAME = 'salesSupport';
export const DATE_RANGE_FILTER_SECTION_NAME = 'dateRange';
export const DATE_RANGE_FAST_SELECTION_FILTER_SECTION_NAME =
  'dateRange.fastSelection';
export const DATE_TYPE_COMPARABLE_FILTER_SECTION_NAME = 'dateTypeComparable';
export const DATE_RANGE_COMPARABLE_FILTER_SECTION_NAME = 'dateRangeComparable';
export const KPI_FILTER_SECTION_NAME = 'kpi';
export const INCREASE_FILTER_SECTION_NAME = 'increase';
export const CURRENCY_FILTER_SECTION_NAME = 'currency';
export const PRODUCTS_TYPE_FILTER_SECTION_NAME = 'productsType';
export const TPC_SECTION_NAME = 'tpc-repartition';
export const DELIVERY_TYPES_SECTION_NAME = 'deliveryTypes-repartition';
export const PROFILE_SECTION_NAME = 'profile';
export const SLIDER = 'slider';
export const INSTORE_VIEW = 'instoreView';
export const OUTSTORE_VIEW = 'outstoreView';
