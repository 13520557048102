import { User, UserManager, UserManagerSettings } from 'oidc-client-ts';
import * as types from '../constants/action-types';
import store from '../store';

const oidcConfig: UserManagerSettings = {
  redirect_uri: `${window.location.origin}/callback`,
  client_id: import.meta.env.VITE_FEDID_CLIENT_ID,
  response_type: 'code',
  post_logout_redirect_uri: window.location.origin,
  scope: 'openid profile',
  authority: import.meta.env.VITE_FEDID_URL,
  silent_redirect_uri: `${window.location.origin}/callback`,
  automaticSilentRenew: false,
  loadUserInfo: true,
};

export const USER_MANAGER = new UserManager(oidcConfig);

export const AUTHENTICATION_SOURCE_URL_KEY = 'authentication-source-url';
export const AUTHENTICATION_TOKEN_KEY = 'authentication-token';

export const redirectToFedid = (): void => {
  const sourceUrl = window.location.pathname + window.location.search;
  localStorage.setItem(AUTHENTICATION_SOURCE_URL_KEY, sourceUrl);
  USER_MANAGER.signinRedirect();
};

export const backToSourceURL = (navigate): void => {
  const storedRedirection = localStorage.getItem(AUTHENTICATION_SOURCE_URL_KEY);
  navigate(storedRedirection);
};

export const cacheUserToken = (user: User): void => {
  localStorage.setItem(AUTHENTICATION_TOKEN_KEY, user.access_token);
};

export const getCachedUserToken = (): string => {
  return localStorage.getItem(AUTHENTICATION_TOKEN_KEY);
};

export const resetUser = () => {
  return store.dispatch({
    type: types.ResetUser,
    payload: null,
  });
};

export const forgetUserToken = (): void => {
  localStorage.removeItem(AUTHENTICATION_TOKEN_KEY);
};

export const hasCodeInUrl = (location: Location = window.location): boolean => {
  const searchParams = new URLSearchParams(location.search);
  const hashParams = new URLSearchParams(location.hash.replace('#', '?'));

  return Boolean(
    searchParams.get('code') ||
      searchParams.get('id_token') ||
      searchParams.get('session_state') ||
      hashParams.get('code') ||
      hashParams.get('id_token') ||
      hashParams.get('session_state'),
  );
};
