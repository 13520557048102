import React from 'react';
import { I18n } from 'react-redux-i18n';
import { VpMapPinIcon } from '@vtmn-play/icons/react';

import { generateParams } from 'common/utils/navigation';
import ModelFilters from 'common/models/ModelFilters';
import { IModelTable } from 'common/models/interfaces/table';
import ModelToolbar from 'common/models/ModelToolbar';
import { IPageParams } from 'common/models/interfaces/popup-selector';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
) => {
  let link = '/regions';

  link += generateParams(filters, table, toolbar, pageParams);

  return link;
};

export default {
  title: () => I18n.t(`navigation.label.regions`),
  id: 'regions',
  icon: (
    <VpMapPinIcon
      size={24}
      style={{
        width: '30px',
        height: '28px',
        marginLeft: '-6px',
        fontSize: '5rem',
      }}
    />
  ),
  order: 3,
  link: getLinkBySelectors,
  paths: ['/regions'],
  subLinks: null,
};
