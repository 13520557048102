import { filter, get, isEmpty, isEqual, map } from 'lodash';

import ModelProductHierarchy from 'common/models/hierarchies/products/product-hierarchy';
import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import ModelTypeTreeLevel from 'common/models/enums/models/type-tree-level';

import * as Types from 'common/constants/action-types';

import ApiRequestUtils from 'common/api/api-request';
import DateUtils from 'common/utils/date';
import store from 'common/store';
import ModelToolbar from 'common/models/ModelToolbar';

export const loadInitRequest = () => {
  const url = ApiRequestUtils.getBffUrl('init', 'v4', null, {
    from: DateUtils.getYesterday(),
  });

  const { user } = store.getState().authentication;
  const { i18n } = store.getState();

  return ApiRequestUtils.request(url, user?.access_token, i18n.locale, {
    isRetryableRequest: true,
    isCancellableRequest: false,
  });
};

export const loadLabelsRequest = (
  typeTreeLevel?: ModelTypeTreeLevel,
  toolbar?: ModelToolbar,
  modelsIds?: number[],
) => {
  const { user } = store.getState().authentication;
  const { i18n } = store.getState();

  const url = ApiRequestUtils.getBffUrl(
    typeTreeLevel?.urlLabel || 'labels/geographical',
    'v4',
    null,
    {
      aggLevel: typeTreeLevel?.aggLevel,
      from: DateUtils.getYesterday(),
      family:
        isEqual(typeTreeLevel, TypeTreeLevel.MODEL) && toolbar?.families
          ? map(toolbar.families, (family) => family.id)
          : undefined,
      models:
        isEqual(typeTreeLevel, TypeTreeLevel.MODEL) && !isEmpty(modelsIds)
          ? modelsIds
          : undefined,
    },
  );

  return ApiRequestUtils.request(url, user?.access_token, i18n.locale, {
    isRetryableRequest: true,
    isCancellableRequest: false,
  });
};

export const updateModelsLabels = (models: number[]) => (dispatch) => {
  const hierarchies = store.getState().global.models;

  const modelsToLoad = models.filter(
    (model) =>
      !hierarchies.find((productHierarchy) => productHierarchy.id === model),
  );

  if (isEmpty(modelsToLoad)) {
    return null;
  }

  return loadLabelsRequest(TypeTreeLevel.MODEL, null, modelsToLoad).then(
    (response) => {
      const productHierarchiesUpdated: ModelProductHierarchy[] = filter(
        map(get(response, 'data'), (r) =>
          ModelProductHierarchy.fromApiModelProductHierarchy(r),
        ),
        (productHierarchy) =>
          productHierarchy.label && productHierarchy.label.trim() !== '',
      );

      dispatch({
        type: Types.FindHierarchies,
        payload: {
          models: [...hierarchies, ...productHierarchiesUpdated],
        },
      });
    },
  );
};
