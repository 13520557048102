import { isEqual } from 'lodash';

import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelSaleSupport from 'common/models/enums/models/sale-support';
import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import TypeTableViewOptionEnum from './TypeTableViewOptionEnum';

export default class TypeSaleSupport extends AbstractEnumType {
  public static PHYSICAL_STORE = new ModelSaleSupport(
    'physicalStore',
    'physical_store',
    0,
    'application.filter.sales-support.physical-store',
    'shopping-cart-line',
    (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    TypeTableViewOptionEnum.IN_STORE,
    'physical_store',
  );

  public static DIGITAL_STORE = new ModelSaleSupport(
    'digitalStore',
    'digital_store',
    1,
    'application.filter.sales-support.digital-store',
    'add-circle-line',
    (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    TypeTableViewOptionEnum.IN_STORE,
    'digital_store',
    true,
  );

  public static DECAPRO_IN = new ModelSaleSupport(
    'decaproInStore',
    'decapro_in_store',
    2,
    'application.filter.sales-support.decaproIn',
    'suitcase-line',
    (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    TypeTableViewOptionEnum.IN_STORE,
    'decaproInStore',
    true,
  );

  public static CLICK_COLLECT = new ModelSaleSupport(
    'clickAndCollect',
    'click_and_collect',
    3,
    'application.filter.sales-support.c&c',
    'tablet-line',
    (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    TypeTableViewOptionEnum.OUT_STORE,
    'click_and_collect',
    true,
  );

  public static INTERNET = new ModelSaleSupport(
    'internet',
    null,
    4,
    'application.filter.sales-support.internet',
    'macbook-line',
    (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    TypeTableViewOptionEnum.OUT_STORE,
    'internet',
    true,
  );

  public static ECOMMERCE = new ModelSaleSupport(
    'ecommerce',
    null,
    5,
    'application.filter.sales-support.ecommerce',
    'link-line',
    (type) => !isEqual(TypeTreeLevel.STORE, type),
    TypeTableViewOptionEnum.OUT_STORE,
    'ecommerce',
    true,
  );

  public static DECAPRO_OUT = new ModelSaleSupport(
    'decaproOutStore',
    'decapro_out_store',
    6,
    'application.filter.sales-support.decaproOut',
    'suitcase-line',
    (type) => !isEqual(TypeTreeLevel.ECOMMERCE, type),
    TypeTableViewOptionEnum.OUT_STORE,
    'decaproOutStore',
    true,
  );

  public static ALL = this.all<ModelSaleSupport>(
    TypeSaleSupport,
    ModelSaleSupport,
  )
    .filter((saleSupport) => saleSupport.enabled)
    .sort((a, b) => a.weight - b.weight);

  public static ALL_INSTORE = this.all<ModelSaleSupport>(
    TypeSaleSupport,
    ModelSaleSupport,
  )
    .filter(
      (saleSupport) =>
        saleSupport.enabled &&
        saleSupport.type === TypeTableViewOptionEnum.IN_STORE,
    )
    .sort((a, b) => a.weight - b.weight);

  public static ALL_OUTSTORE = this.all<ModelSaleSupport>(
    TypeSaleSupport,
    ModelSaleSupport,
  )
    .filter(
      (saleSupport) =>
        saleSupport.enabled &&
        saleSupport.type === TypeTableViewOptionEnum.OUT_STORE,
    )
    .sort((a, b) => a.weight - b.weight);

  public static isEqualSalesSupport(
    salesSupport: ModelSaleSupport[],
    comparedValues: ModelSaleSupport[],
  ): boolean {
    const salesSupportToStrings = comparedValues
      ? salesSupport.map((saleSupport) => saleSupport.id)
      : null;

    return isEqual(salesSupportToStrings, comparedValues);
  }

  public static byId(searchId: string): ModelSaleSupport {
    return this.by(this, ModelSaleSupport, 'id', searchId);
  }
}
