import { reducerFactory } from 'common/utils/reducer';

import INITIAL_STATE from 'common/constants/initial-state-filters';
import {
  UpdateFilters,
  UpdateFiltersType,
} from 'common/constants/action-types';
import { IAction } from 'common/models/action';
import { IStateTable } from 'common/constants/initial-state-table';

const handlers = {};

handlers[UpdateFilters] = (
  state: IStateTable,
  action: IAction<UpdateFiltersType>,
) => {
  return action.payload;
};

export default reducerFactory(INITIAL_STATE, handlers);
