import Axios, { CancelTokenSource } from 'axios';

interface IListRequestPendingResponse {
  [key: string]: IRequestPendingResponse;
}

interface IRequestPendingResponse {
  url: string;
  method: string;
  withPartialContentResponse: boolean;
  status: string;
  token: CancelTokenSource;
  timeoutId?: number;
}

const allRequests: IListRequestPendingResponse = {};

export const SEND_STATUS = 'SEND';
export const CANCEL_STATUS = 'CANCEL';
export const ABORT_STATUS = 'ABORT';
export const RECEIVE_STATUS = 'RECEIVE';
export default class ApiCancelRequestUtils {
  private static generateKeyFromUrlAndMethod(
    url: string,
    method: string,
  ): string {
    return `${method.toUpperCase()}_${url}`;
  }

  public static addRequestToCancellableAndCancelOldRequest(
    url: string,
    method: string,
    withPartialContentResponse = false,
  ): CancelTokenSource {
    const requestUrl = this.generateKeyFromUrlAndMethod(url, method);

    this.cancelRequestWithPendingResponse(requestUrl);

    return this.generateCancelToken(
      requestUrl,
      url,
      method,
      withPartialContentResponse,
    );
  }

  public static removeRequestToCancellableOrSetStatus(
    url: string,
    method: string,
  ) {
    const requestUrl = this.generateKeyFromUrlAndMethod(url, method);
    if (allRequests[requestUrl]) {
      if (allRequests[requestUrl].withPartialContentResponse) {
        allRequests[requestUrl].status = RECEIVE_STATUS;
      } else {
        allRequests[requestUrl] = null;
      }
    }
  }

  public static cancelAllRequests() {
    Object.keys(allRequests)
      .filter(this.requestIsNotCancelResponse.bind(this))
      .forEach(this.cancelRequestWithPendingResponse.bind(this));
  }

  private static requestIsNotCancelResponse(key: string): boolean {
    return (
      !!allRequests[key] &&
      allRequests[key].status !== CANCEL_STATUS &&
      allRequests[key].status !== ABORT_STATUS
    );
  }

  private static requestIsPendingResponse(key: string): boolean {
    return !!allRequests[key] && allRequests[key].status === SEND_STATUS;
  }

  private static cancelRequestWithPendingResponse(key: string): void {
    if (allRequests[key]) {
      if (this.requestIsPendingResponse(key)) {
        allRequests[key].token.cancel();
      }

      if (allRequests[key].timeoutId) {
        window.clearTimeout(allRequests[key].timeoutId);
      }

      allRequests[key].status = this.requestIsPendingResponse(key)
        ? CANCEL_STATUS
        : ABORT_STATUS;
    }
  }

  private static generateCancelToken(
    key: string,
    url: string,
    method: string,
    withPartialContentResponse: boolean,
  ): CancelTokenSource {
    const requestToken = Axios.CancelToken.source();

    // Axios issues : it merges the cancel token incorrectly, so we trick it and move it from the prototype to the object itself
    requestToken.token.throwIfRequested =
      requestToken.token.throwIfRequested.bind(requestToken.token); // eslint-disable-line @typescript-eslint/unbound-method
    requestToken.token.promise.then = requestToken.token.promise.then.bind(
      requestToken.token.promise,
    ); // eslint-disable-line @typescript-eslint/unbound-method
    requestToken.token.promise.catch = requestToken.token.promise.catch.bind(
      requestToken.token.promise,
    ); // eslint-disable-line @typescript-eslint/unbound-method

    allRequests[key] = {
      url,
      method,
      withPartialContentResponse,
      status: SEND_STATUS,
      token: requestToken,
    };

    return requestToken;
  }

  public static addTimeoutId(url: string, method: string, timeoutId: number) {
    const key = this.generateKeyFromUrlAndMethod(url, method);
    if (allRequests[key]) {
      allRequests[key].timeoutId = timeoutId;
    }
  }
}
