import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelIncrease from 'common/models/enums/models/increase';

export default class TypeIncrease extends AbstractEnumType {
  public static PROGRESSION = new ModelIncrease(
    'progression',
    'application.filter.increase.progression',
    'application.table.title.prog',
    '_progression',
  );

  public static GROWTH = new ModelIncrease(
    'growth',
    'application.filter.increase.growth',
    'application.table.title.growth',
    '_growth',
  );

  public static ALL: ModelIncrease[] = this.all(TypeIncrease, ModelIncrease);

  public static byValue(
    searchValue: string,
    defaultValue?: ModelIncrease,
  ): ModelIncrease | null {
    return this.by(this, ModelIncrease, 'value', searchValue, defaultValue);
  }
}
