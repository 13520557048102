import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import TypeUnitEnum from 'common/models/enums/types/unit-enum';
import ModelKpi from 'common/models/enums/models/kpi-enum';
import { isEqual } from 'lodash';

export default class TypeKpiEnum extends AbstractEnumType {
  public static GMV = new ModelKpi(
    'gmv',
    null,
    'gmv',
    'kpi',
    1,
    TypeUnitEnum.CURRENCY,
    TypeUnitEnum.PERCENTAGE,
  );

  public static GMV_QUANTITY = new ModelKpi(
    'gmv_qty',
    null,
    'gmv-quantity',
    'kpi',
    5,
    TypeUnitEnum.NONE,
    TypeUnitEnum.PERCENTAGE,
  );

  public static MARGIN = new ModelKpi(
    'margin',
    null,
    'margin',
    'kpi',
    2,
    TypeUnitEnum.CURRENCY,
    TypeUnitEnum.PERCENTAGE,
  );

  public static TICKET = new ModelKpi(
    'ticket',
    null,
    'ticket',
    'kpi',
    4,
    TypeUnitEnum.NONE,
    TypeUnitEnum.PERCENTAGE,
  );

  public static AOV = new ModelKpi(
    'aov',
    'aov',
    'aov',
    'kpi',
    6,
    TypeUnitEnum.CURRENCY,
    TypeUnitEnum.PERCENTAGE,
    false,
    2,
  );

  public static GMV_MARGIN_RATE = new ModelKpi(
    'gmv_margin_rate',
    'gmv_margin_rate',
    'gmv-margin-rate',
    'kpi',
    3,
    TypeUnitEnum.PERCENTAGE,
    TypeUnitEnum.POINTS,
    false,
    2,
  );

  public static ALL = this.all<ModelKpi>(TypeKpiEnum, ModelKpi);

  public static ALL_CURRENCY = this.all<ModelKpi>(TypeKpiEnum, ModelKpi).filter(
    (e) => isEqual(e.unitVolume, TypeUnitEnum.CURRENCY),
  );

  public static byValue(
    searchValue: string,
    defaultValue?: ModelKpi,
  ): ModelKpi {
    return this.by(this, ModelKpi, 'value', searchValue, defaultValue);
  }
}
