import * as types from 'common/constants/action-types';
import { isEqual } from 'lodash';
import { IModelTable } from 'common/models/interfaces/table';

export const initializeTableOptions =
  (table: Partial<IModelTable>) => (dispatch) => {
    dispatch({
      type: types.InitializeTableOptions,
      payload: {
        table,
      },
    });
  };

export const updateTableByValues =
  (table: IModelTable, newTableValues: Partial<IModelTable>) => (dispatch) => {
    const newTable: Partial<IModelTable> = {};

    if (
      newTableValues.columns &&
      !isEqual(table.columns, newTableValues.columns)
    ) {
      newTable.columns = newTableValues.columns;
    }

    if (
      newTableValues.viewOptions &&
      !isEqual(table.viewOptions, newTableValues.viewOptions)
    ) {
      newTable.viewOptions = newTableValues.viewOptions;
    }

    if (!isEqual(table.fixHeader, newTableValues.fixHeader)) {
      newTable.fixHeader = newTableValues.fixHeader;
    }

    if (!isEqual(table.showBorder, newTableValues.showBorder)) {
      newTable.showBorder = newTableValues.showBorder;
    }

    if (!isEqual(table.paginate, newTableValues.paginate)) {
      newTable.paginate = newTableValues.paginate;
    }

    if (
      newTableValues.sortBy &&
      !isEqual(table.sortBy, newTableValues.sortBy)
    ) {
      newTable.sortBy = newTableValues.sortBy;
    }

    if (
      newTableValues.aggregation &&
      !isEqual(table.aggregation, newTableValues.aggregation)
    ) {
      newTable.aggregation = newTableValues.aggregation;
    }

    dispatch({
      type: types.UpdateTable,
      payload: {
        table: newTable,
      },
    });
  };
