import { TIME_DAY, TIMESTAMP_SECOND } from 'common/constants/time';

export interface ICookie {
  name: string;
  value: string;
  expires: Date;
  path: string;
  secure: boolean;
}

export default class Cookie {
  public static get(cookieName = ''): ICookie {
    if (
      typeof document.cookie === 'string' &&
      document.cookie.trim() !== '' &&
      typeof cookieName === 'string'
    ) {
      const name = cookieName.trim();
      const cookies = document.cookie.split(';');

      const foundCookies = cookies
        .map((cookieString) => this.constructCookie(cookieString))
        .find((cookie) => cookie.name === name);

      if (!foundCookies) {
        return null;
      }

      return foundCookies;
    }

    return null;
  }

  private static constructCookie(cookie: string): ICookie {
    const cookiePart = cookie.split('=');

    return {
      name: typeof cookiePart[0] === 'string' ? cookiePart[0].trim() : '',
      value:
        typeof cookiePart[1] === 'string'
          ? decodeURIComponent(cookiePart.slice(1).join('='))
          : '',
      expires: null,
      path: null,
      secure: false,
    };
  }

  public static set(
    name: string,
    value: string,
    expireTimes: number = TIME_DAY,
    isSecure = false,
  ): ICookie {
    const date = new Date();
    date.setTime(date.getTime() + expireTimes * TIMESTAMP_SECOND);

    const cookie = {
      name,
      value,
      expires: expireTimes !== 0 ? date : null,
      path: '/',
      secure: isSecure,
    };

    const expiresDate =
      cookie.expires !== null ? cookie.expires.toUTCString() : '0';
    const encodeValue = encodeURIComponent(cookie.value);
    const secureData = cookie.secure ? ';secure' : '';

    document.cookie = `${cookie.name}=${encodeValue};expires=${expiresDate};path=${cookie.path}${secureData}`;

    return cookie;
  }

  public static remove(name: string): void {
    this.set(name, '', -1);
  }
}
