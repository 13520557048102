import { I18n } from 'react-redux-i18n';
import { isEqual, orderBy } from 'lodash';

import ModelTypeTreeLevel from 'common/models/enums/models/type-tree-level';
import CallbackString from 'common/models/types/callback-string';
import StringOrNumberType from 'common/models/types/string-number';

import { toCapitalizeWorld } from 'common/utils/global';
import { ISelectOption } from 'common/models/interfaces/select-search-option';

abstract class AbstractModelHierarchy {
  public readonly id: StringOrNumberType;

  public readonly type: ModelTypeTreeLevel;

  public readonly label: string;

  constructor(
    id: StringOrNumberType,
    type: ModelTypeTreeLevel,
    label: string | CallbackString,
  ) {
    this.id = id;
    this.type = type;
    this.label = label
      ? this.constructlLabel(typeof label === 'function' ? label() : label)
      : id.toString();
  }

  public get idUnique(): string {
    return `${this.type.value}-${this.id}`;
  }

  public get labelWithId(): string {
    return this.constructLabelWithId();
  }

  protected constructLabelWithId(): string {
    return this.type.showIdInTable ? `${this.id} - ${this.label}` : this.label;
  }

  public get idHuman(): string {
    return this.id.toString();
  }

  protected constructlLabel(label: string): string {
    return label
      ? AbstractModelHierarchy.cleanLabel(label)
      : this.undefinedLabel;
  }

  protected get undefinedLabel() {
    return I18n.t('application.label.undefined-id', { id: this.id });
  }

  protected static cleanLabel(label: string): string {
    return toCapitalizeWorld(label.trim().replace(/\.$/, ''));
  }

  public toString(): string {
    return `${this.id}-${this.type.value}`;
  }

  public toOption(customProperties?: Partial<ISelectOption>): ISelectOption {
    return {
      id: this.id,
      value: this.idUnique,
      label: this.labelWithId || this.id.toString(),
      level: this.type,
      ...customProperties,
    };
  }

  public isEqual(hierarchy: AbstractModelHierarchy): boolean {
    return (
      hierarchy && hierarchy.id === this.id && hierarchy.type === this.type
    );
  }

  public static findHierarchy<T extends AbstractModelHierarchy>(
    hierarchies: T[],
    elementId: StringOrNumberType,
    elementType: ModelTypeTreeLevel,
  ): T {
    if (!elementId) {
      return null;
    }

    return hierarchies && hierarchies.length > 0
      ? hierarchies.find(
          (hierarchy) =>
            isEqual(hierarchy.id.toString(), elementId.toString()) &&
            isEqual(hierarchy.type, elementType),
          null,
        )
      : null;
  }

  public static getHierarchiesOrdered(
    hierarchies: AbstractModelHierarchy[],
  ): AbstractModelHierarchy[] {
    return orderBy(hierarchies, 'label', 'asc');
  }
}

export default AbstractModelHierarchy;
