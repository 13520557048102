import { map } from 'lodash';

import { IModelTable } from 'common/models/interfaces/table';
import ModelToolbar from 'common/models/ModelToolbar';
import ModelFilters from 'common/models/ModelFilters';
import { IPageParams } from 'common/models/interfaces/popup-selector';

export const generateTableParams = (table: IModelTable): string[] => {
  const tableParams = [];

  if (table.columns) {
    const columnsValues = map(table.columns, (column) => column.value);
    tableParams.push(`columns=${columnsValues.join(',')}`);
  }

  if (table.viewOptions) {
    const tableViewOptions = map(
      table.viewOptions,
      (tableViewOption) => tableViewOption.value,
    );
    tableParams.push(`views=${tableViewOptions.join(',')}`);
  }

  return tableParams;
};

export const generateParams = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
): string => {
  const tableParams = generateTableParams(table);

  const params = [
    ...toolbar.queryParams(pageParams),
    ...filters.queryParams(),
    ...tableParams,
  ];

  return `?${params.join('&')}`;
};
