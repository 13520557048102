import React from 'react';
import { I18n } from 'react-redux-i18n';
import { VpStarIcon } from '@vtmn-play/icons/react';

import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import { generateParams } from 'common/utils/navigation';
import { IModelTable } from 'common/models/interfaces/table';
import ModelFilters from 'common/models/ModelFilters';
import ModelToolbar from 'common/models/ModelToolbar';
import { IPageParams } from 'common/models/interfaces/popup-selector';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
  aggLevel = TypeTreeLevel.PAYS,
) => {
  let link = `/favorites/${aggLevel.urlKey}`;

  link += generateParams(filters, table, toolbar, pageParams);

  return link;
};

export default {
  id: 'favorites',
  title: () => I18n.t(`navigation.label.favorites`),
  icon: <VpStarIcon style={{ fontSize: '3rem' }} />,
  order: 10,
  link: getLinkBySelectors,
  paths: ['/favorites/:aggLevel'],
  subLinks: [
    {
      id: 'favorites-countries',
      title: () => I18n.t(`navigation.label.favorite.country`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.PAYS,
        ),
      paths: ['/favorites/countries'],
    },
    {
      id: 'favorites-stores',
      title: () => I18n.t(`navigation.label.favorite.store`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.STORE,
        ),
      paths: ['/favorites/stores'],
    },
    {
      id: 'favorites-department',
      title: () => I18n.t(`navigation.label.favorite.department`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.DEPARTMENT,
        ),
      paths: ['/favorites/sports'],
    },
    {
      id: 'favorites-sub-departments',
      title: () => I18n.t(`navigation.label.favorite.sub-department`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.SUB_DEPARTMENT,
        ),
      paths: ['/favorites/subSports'],
    },
    {
      id: 'favorites-families',
      title: () => I18n.t(`navigation.label.favorite.family`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.FAMILY,
        ),
      paths: ['/favorites/families'],
    },
    {
      id: 'favorites-models',
      title: () => I18n.t(`navigation.label.favorite.model`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.MODEL,
        ),
      paths: ['/favorites/models'],
    },
  ],
};
