import React, { useState } from 'react';
import { Modal } from 'antd';
import { useI18n } from 'common/utils/hookI18n';

import LocalStorageUtils from 'common/utils/local-storage';
import { TIME_WEEK } from 'common/constants/time';

function PopupGMV() {
  const [openModal, setOpenModal] = useState(true);

  const I18n = useI18n();
  const handleOk = () => {
    setOpenModal(false);
    LocalStorageUtils.setItem('viewGmv', 'true', TIME_WEEK);
  };

  const handleCancel = () => {
    setOpenModal(false);
    LocalStorageUtils.setItem('viewGmv', 'false');
  };

  return (
    LocalStorageUtils.getItem('viewGmv') === 'false' && (
      <div>
        <Modal
          title={I18n.t('application.titleInfoGmv')}
          open={openModal}
          onOk={handleOk}
          okButtonProps={{ id: 'validateGmv' }}
          okText={I18n.t('application.okGmv')}
          onCancel={handleCancel}
          cancelButtonProps={{
            disabled: true,
            ghost: true,
            style: { color: 'transparent', border: 'none', cursor: 'unset' },
          }}
          closable={false}
        >
          <div>
            <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              {I18n.t('application.updateGmv')}
            </div>
            <div>{I18n.t('application.infoGmv')}</div>
            <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              {I18n.t('application.aboutGmv')}
            </div>
            <div>
              {I18n.t('application.acronymGmv')} <br />
              <br />
              <div>
                <b>{I18n.t('application.splitPartsGmv')}</b>
              </div>
              <div>{I18n.t('application.firstPartGmv')}</div>
              <div>{I18n.t('application.secondPartGmv')}</div>
              <div>{I18n.t('application.thirdPartGmv')}</div>
            </div>
          </div>
        </Modal>
      </div>
    )
  );
}

export default PopupGMV;
