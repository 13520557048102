import React from 'react';
import { VpFootballIcon } from '@vtmn-play/icons/react';
import { I18n } from 'react-redux-i18n';

import ModelFilters from 'common/models/ModelFilters';
import { IModelTable } from 'common/models/interfaces/table';
import ModelToolbar from 'common/models/ModelToolbar';
import { generateParams } from 'common/utils/navigation';
import { IPageParams } from 'common/models/interfaces/popup-selector';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
) => {
  let link = '/sports';

  link += generateParams(filters, table, toolbar, pageParams);

  return link;
};

const ProductNavigation = {
  id: 'sports',
  title: () => I18n.t(`navigation.label.sports`),
  icon: <VpFootballIcon style={{ fontSize: '4rem', marginLeft: '-3px' }} />,
  order: 4,
  link: getLinkBySelectors,
  paths: [
    '/sports',
    '/sports/:codePays',
    '/sports/:typeStore/:idStore',
    '/sports/:codePays/:typeStore/:idStore',
    '/sports/:codePays/:typeStore/:idStore/:idDepartment/subDepartments',
    '/sports/:codePays/:typeStore/:idStore/:idSubDepartment/families',
    '/sports/:codePays/:typeStore/:idStore/:idFamily/models',
    '/sports/:codePays/:idDepartment/subDepartments',
    '/sports/:codePays/:idSubDepartment/families',
    '/sports/:codePays/:idFamily/models',
    '/sports/:idDepartment/subDepartments',
    '/sports/:idSubDepartment/families',
    '/sports/:idFamily/models',
  ],
  subLinks: null,
};

export default ProductNavigation;
