import { reducerFactory } from 'common/utils/reducer';

import INITIAL_STATE from 'common/constants/initial-state-toolbar';
import { UpdateTableType, UpdateToolbar } from 'common/constants/action-types';
import { IAction } from 'common/models/action';
import { IStateTable } from 'common/constants/initial-state-table';

const handlers = {};

handlers[UpdateToolbar] = (
  state: IStateTable,
  action: IAction<UpdateTableType>,
) => {
  return action.payload;
};

export default reducerFactory(INITIAL_STATE, handlers);
