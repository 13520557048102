import AbstractModelProductHierarchy from 'common/models/hierarchies/products/abstract-product-hierarchy';
import IApiModelProductHierarchyParent from 'common/models/hierarchies/products/api-product-hierarchy-parent';

export default class ModelProductHierarchyParent extends AbstractModelProductHierarchy {
  public static fromApiModelProductHierarchyParent(
    data: IApiModelProductHierarchyParent,
  ) {
    return new ModelProductHierarchyParent(data.id, data.level, null);
  }
}
