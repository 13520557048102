import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import Optional from 'common/models/types/optional';
import ModelProductTypeFilter from 'common/models/enums/models/product-type-filter';

export default class TypeProductsTypeFilter extends AbstractEnumType {
  public static readonly ONE_PRODUCT = new ModelProductTypeFilter(
    '1P',
    'application.filter.productType.1p',
  );

  public static readonly THIRD_PRODUCT = new ModelProductTypeFilter(
    '3P',
    'application.filter.productType.3p',
  );

  public static readonly ALL: ModelProductTypeFilter[] = this.all(
    TypeProductsTypeFilter,
    ModelProductTypeFilter,
  );

  public static byValue(
    searchValue: string,
    defaultValue?: ModelProductTypeFilter,
  ): Optional<ModelProductTypeFilter> {
    return this.by(
      this,
      ModelProductTypeFilter,
      'value',
      searchValue,
      defaultValue,
    );
  }
}
