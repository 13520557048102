import React from 'react';
import classnames from 'classnames';

interface ILogoLoaderProps {
  readonly className: string;
}

function LogoLoader({ className = null }: ILogoLoaderProps) {
  return (
    <div className={classnames('logo-loader', className)}>
      <div className="big-circle-world" />
      <div className="medium-circle-orange" />
      <div className="small-circle-blue" />
      <div className="big-circle-blue" />
    </div>
  );
}

export default LogoLoader;
