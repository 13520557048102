import { assign, omit, reduce, without } from 'lodash';

import INITIAL_STATE, {
  IStateGlobal,
} from 'common/constants/initial-state-global';

import { IAction } from 'common/models/action';
import * as types from 'common/constants/action-types';
import * as FavoriteActionTypes from 'pages/favorites/constants/action-types';

import { reducerFactory } from 'common/utils/reducer';
import { IProfileContent } from 'common/models/interfaces/profiles';

const updateContentOfProfile = (
  state: IStateGlobal,
  profileId: number,
  content: Partial<IProfileContent>,
): IStateGlobal => {
  return {
    ...state,
    profiles: {
      ...state.profiles,
      [profileId]: {
        ...state.profiles[profileId],
        content: {
          ...state.profiles[profileId].content,
          ...content,
        },
      },
    },
  };
};

const handlers = {};

handlers[types.ChangeHeader] = (
  state: IStateGlobal,
  action: IAction<types.ChangeHeaderType>,
) => {
  return assign({}, state, {
    header: assign({}, state.header, {
      propsPopupSelector: action.payload.propsPopupSelector,
      disabledFlag: action.payload.disabledFlag,
      liveDate: action.payload.liveDate || false,
      showFilters: action.payload.showFilters,
      infoBubbleFilters: action.payload.infoBubbleFilters,
    }),
  });
};

handlers[types.InitApp] = (
  state: IStateGlobal,
  action: IAction<types.InitAppType>,
) => {
  const profiles = reduce(
    action.payload.profiles,
    (accumulator, profile) => {
      return {
        ...accumulator,
        [profile.id]: {
          ...profile,
          content: profile.content && {
            ...omit(profile.content, 'sub_departments'),
            subDepartments: profile.content.sub_departments,
          },
        },
      };
    },
    {},
  );

  return assign({}, state, {
    userInfo: action.payload.user_details,
    messages: action.payload.messages,
    profiles,
  });
};

handlers[types.FindHierarchies] = (
  state: IStateGlobal,
  action: IAction<types.FindHierarchiesType>,
) => {
  return {
    ...state,
    countries: action.payload.countries || state.countries,
    stores: action.payload.stores || state.stores,
    departments: action.payload.departments || state.departments,
    subDepartments: action.payload.subDepartments || state.subDepartments,
    families: action.payload.families || state.families,
    models: action.payload.models || state.models,
  };
};

handlers[FavoriteActionTypes.AddFavoriteCountry] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeAddFavoriteCountry>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    countries: [
      ...state.profiles[action.payload.profile].content.countries,
      action.payload.country,
    ],
  });
};

handlers[FavoriteActionTypes.AddFavoriteStore] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeAddFavoriteStore>,
) => {
  let updateProfileContentStoresList;
  if (action.payload.store) {
    updateProfileContentStoresList = [
      ...state.profiles[action.payload.profile].content.stores,
      action.payload.store,
    ];
  } else if (action.payload.stores) {
    updateProfileContentStoresList = [
      ...state.profiles[action.payload.profile].content.stores,
      ...action.payload.stores,
    ];
  }

  return updateContentOfProfile(state, action.payload.profile, {
    stores: updateProfileContentStoresList,
  });
};

handlers[FavoriteActionTypes.AddFavoriteEcommerce] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeAddFavoriteEcommerce>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    ecommerces: [
      ...state.profiles[action.payload.profile].content.ecommerces,
      action.payload.ecommerce,
    ],
  });
};

handlers[FavoriteActionTypes.AddFavoriteDepartment] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeAddFavoriteDepartment>,
) => {
  let updateProfileContentStoresList;
  if (action.payload.department) {
    updateProfileContentStoresList = [
      ...state.profiles[action.payload.profile].content.departments,
      action.payload.department,
    ];
  } else if (action.payload.departments) {
    updateProfileContentStoresList = [
      ...state.profiles[action.payload.profile].content.departments,
      ...action.payload.departments,
    ];
  }

  return updateContentOfProfile(state, action.payload.profile, {
    departments: updateProfileContentStoresList,
  });
};

handlers[FavoriteActionTypes.AddFavoriteSubDepartment] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeAddFavoriteSubDepartment>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    subDepartments: [
      ...state.profiles[action.payload.profile].content.subDepartments,
      action.payload.subDepartment,
    ],
  });
};

handlers[FavoriteActionTypes.AddFavoriteFamily] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeAddFavoriteFamily>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    families: [
      ...state.profiles[action.payload.profile].content.families,
      action.payload.family,
    ],
  });
};

handlers[FavoriteActionTypes.AddFavoriteModel] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeAddFavoriteModel>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    models: [
      ...state.profiles[action.payload.profile].content.models,
      action.payload.model,
    ],
  });
};

handlers[FavoriteActionTypes.RemoveFavoriteCountry] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeRemoveFavoriteCountry>,
) => {
  const favoritesCountries =
    state.profiles[action.payload.profile].content.countries;

  return updateContentOfProfile(state, action.payload.profile, {
    countries: without(favoritesCountries, action.payload.country),
  });
};

handlers[FavoriteActionTypes.ClearFavoriteCountry] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeClearFavoriteCountry>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    countries: [],
  });
};

handlers[FavoriteActionTypes.RemoveFavoriteStore] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeRemoveFavoriteStore>,
) => {
  const favoritesStores = state.profiles[action.payload.profile].content.stores;

  return updateContentOfProfile(state, action.payload.profile, {
    stores: without(favoritesStores, action.payload.store),
  });
};

handlers[FavoriteActionTypes.ClearFavoriteStore] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeClearFavoriteStore>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    stores: [],
  });
};

handlers[FavoriteActionTypes.RemoveFavoriteEcommerce] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeRemoveFavoriteEcommerce>,
) => {
  const favoritesEcommerces =
    state.profiles[action.payload.profile].content.ecommerces;

  return updateContentOfProfile(state, action.payload.profile, {
    ecommerces: without(favoritesEcommerces, action.payload.ecommerce),
  });
};

handlers[FavoriteActionTypes.ClearFavoriteEcommerce] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeClearFavoriteEcommerce>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    ecommerces: [],
  });
};

handlers[FavoriteActionTypes.RemoveFavoriteDepartment] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeRemoveFavoriteDepartment>,
) => {
  const favoritesDepartments =
    state.profiles[action.payload.profile].content.departments;

  return updateContentOfProfile(state, action.payload.profile, {
    departments: without(favoritesDepartments, action.payload.department),
  });
};

handlers[FavoriteActionTypes.ClearFavoriteDepartment] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeClearFavoriteDepartment>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    departments: [],
  });
};

handlers[FavoriteActionTypes.RemoveFavoriteSubDepartment] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeRemoveFavoriteSubDepartment>,
) => {
  const favoritesSubDepartments =
    state.profiles[action.payload.profile].content.subDepartments;

  return updateContentOfProfile(state, action.payload.profile, {
    subDepartments: without(
      favoritesSubDepartments,
      action.payload.subDepartment,
    ),
  });
};

handlers[FavoriteActionTypes.ClearFavoriteSubDepartment] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeClearFavoriteSubDepartment>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    subDepartments: [],
  });
};

handlers[FavoriteActionTypes.RemoveFavoriteFamily] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeRemoveFavoriteFamily>,
) => {
  const favoritesFamilies =
    state.profiles[action.payload.profile].content.families;

  return updateContentOfProfile(state, action.payload.profile, {
    families: without(favoritesFamilies, action.payload.family),
  });
};

handlers[FavoriteActionTypes.ClearFavoriteFamily] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeClearFavoriteFamily>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    families: [],
  });
};

handlers[FavoriteActionTypes.RemoveFavoriteModel] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeRemoveFavoriteModel>,
) => {
  const favoritesModels = state.profiles[action.payload.profile].content.models;

  return updateContentOfProfile(state, action.payload.profile, {
    models: without(favoritesModels, action.payload.model),
  });
};

handlers[FavoriteActionTypes.ClearFavoriteModel] = (
  state: IStateGlobal,
  action: IAction<FavoriteActionTypes.TypeClearFavoriteModel>,
) => {
  return updateContentOfProfile(state, action.payload.profile, {
    models: [],
  });
};

handlers[types.LoadProfile] = (
  state: IStateGlobal,
  action: IAction<types.LoadProfileType>,
) => {
  return assign({}, state, {
    profiles: {
      ...state.profiles,
      [action.payload.profile]: {
        ...state.profiles[action.payload.profile],
        content: action.payload.content,
      },
    },
  });
};
handlers[types.GetProfilesSuccess] = (
  state: IStateGlobal,
  action: IAction<types.GetProfilesSuccessType>,
) => {
  return assign({}, state, {
    profiles: reduce(
      action.payload.profiles,
      (accumulator, profile) => {
        return {
          ...accumulator,
          [profile.id]: {
            ...profile,
            ...state.profiles[profile.id],
          },
        };
      },
      {},
    ),
  });
};
handlers[types.SetProfilesDefault] = (
  state: IStateGlobal,
  action: IAction<types.SetProfilesDefaultType>,
) => {
  const previousDefaultProfileId = Object.keys(state.profiles).find(
    (profile) => state.profiles[profile].by_default,
  );

  return assign({}, state, {
    profiles: {
      ...state.profiles,
      [previousDefaultProfileId]: {
        ...state.profiles[previousDefaultProfileId],
        by_default: false,
      },
      [action.payload.profile]: {
        ...state.profiles[action.payload.profile],
        by_default: true,
      },
    },
  });
};
handlers[types.EditProfile] = (
  state: IStateGlobal,
  action: IAction<types.EditProfileType>,
) => {
  return assign({}, state, {
    profiles: {
      ...state.profiles,
      [action.payload.profile.id]: {
        ...state.profiles[action.payload.profile.id],
        ...action.payload.profile,
      },
    },
  });
};
handlers[types.DeleteProfile] = (
  state: IStateGlobal,
  action: IAction<types.DeleteProfileType>,
) => {
  return assign({}, state, {
    profiles: omit(state.profiles, action.payload.profile),
  });
};
handlers[types.DuplicateProfile] = (
  state: IStateGlobal,
  action: IAction<types.DuplicateProfileType>,
) => {
  return assign({}, state, {
    profiles: reduce(
      action.payload.profiles,
      (accumulator, profile) => {
        return {
          ...accumulator,
          [profile.id]: {
            ...profile,
            ...state.profiles[profile.id],
          },
        };
      },
      {},
    ),
  });
};

export default reducerFactory(INITIAL_STATE, handlers);
