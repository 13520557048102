import { I18n } from 'react-redux-i18n';
import StringOrNumberType from 'common/models/types/string-number';
import { IAction } from 'common/models/action';
import {
  ActionTypesAddFavoriteProduct,
  ActionTypesRemoveFavorite,
} from 'pages/favorites/constants/action-types';

import ModelTableAggregation from './ModelTableAggregation';

export default class ModelTypeTreeLevel {
  public readonly name: string;

  public readonly value: number;

  public readonly aggLevel: string;

  public readonly keyFilterId: string;

  public readonly keyHierarchies: string;

  public readonly parent: ModelTypeTreeLevel;

  public readonly urlKey: string;

  public readonly i18n: string;

  public readonly labelAnalytics: string;

  public readonly showIdInTable: boolean;

  public readonly addFavoriteActionType: string;

  public readonly removeFavoriteActionType: string;

  public readonly clearFavoriteActionType: string;

  public readonly paramLink: string;

  public readonly urlLabel: string;

  public readonly aggregation: ModelTableAggregation;

  public readonly urlParam: string;

  constructor(
    name: string,
    value: number,
    aggLevel: string,
    i18n: string,
    addFavoriteActionType: string,
    removeFavoriteActionType: string,
    clearFavoriteActionType: string,
    paramLink: string,
    parent?: ModelTypeTreeLevel,
    labelAnalytics?: string,
    urlKey?: string,
    showIdInTable?: boolean,
    jsKeySingle?: string,
    jsKeyPlural?: string,
    urlLabel?: string,
    aggregation?: ModelTableAggregation,
    urlParam?: string,
  ) {
    this.name = name;
    this.value = value;
    this.aggLevel = aggLevel;
    this.keyFilterId = jsKeySingle || i18n;
    this.keyHierarchies = jsKeyPlural || aggLevel;
    this.parent = parent;
    this.urlKey = urlKey || aggLevel;
    this.addFavoriteActionType = addFavoriteActionType;
    this.removeFavoriteActionType = removeFavoriteActionType;
    this.clearFavoriteActionType = clearFavoriteActionType;
    this.i18n = i18n;
    this.labelAnalytics = labelAnalytics || i18n;
    this.showIdInTable = showIdInTable;
    this.paramLink = paramLink;
    this.urlLabel = urlLabel;
    this.aggregation = aggregation;
    this.urlParam = urlParam;
  }

  public toString(): string {
    return this.name;
  }

  public get label(): string {
    return I18n.t(`application.table.title.${this.i18n}`);
  }

  public createActionAddFavorite(
    profileId: number,
    favoriteId: StringOrNumberType,
  ): IAction<ActionTypesAddFavoriteProduct> {
    return {
      type: this.addFavoriteActionType,
      payload: {
        profile: profileId,
        [this.keyFilterId]: favoriteId,
      } as ActionTypesAddFavoriteProduct,
    };
  }

  public createActionRemoveFavorite(
    profileId: number,
    favoriteId: StringOrNumberType,
  ): IAction<ActionTypesRemoveFavorite> {
    return {
      type: this.removeFavoriteActionType,
      payload: {
        profile: profileId,
        [this.keyFilterId]: favoriteId,
      } as ActionTypesRemoveFavorite,
    };
  }

  public createActionClearFavorite(
    profileId: number,
  ): IAction<ActionTypesRemoveFavorite> {
    return {
      type: this.clearFavoriteActionType,
      payload: {
        profile: profileId,
      } as ActionTypesRemoveFavorite,
    };
  }
}
