export const VERB_HTTP_GET = 'GET';
export const VERB_HTTP_POST = 'POST';
export const VERB_HTTP_DELETE = 'DELETE';

export const STATUS_CODE_OK = 200;
export const STATUS_CODE_CREATED = 201;
export const STATUS_CODE_ACCEPTED = 202;
export const STATUS_CODE_EMPTY_CONTENT = 204;
export const STATUS_CODE_PARTIAL_CONTENT = 206;
export const STATUS_CODE_END_OK = 299;
export const STATUS_CODE_BAD_REQUEST = 400;
export const STATUS_CODE_UNAUTHORIZED = 401;
export const STATUS_CODE_ACCESS_DENIED = 403;
export const STATUS_CODE_REQUEST_TIMEOUT = 408;
export const STATUS_CODE_CONFLICT = 409;
export const STATUS_CODE_URI_TOO_LONG = 414;
export const STATUS_CODE_TOO_MANY_REQUESTS = 429;
export const STATUS_CODE_INTERNAL_SERVER_ERROR = 500;

export const COUNTRY_FLAG_BASE_URL = 'flags/1x1/';

export const CONTENT_TYPE_JSON = 'application/json';
