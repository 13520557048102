import React from 'react';
import { Col, Collapse, Result, Row } from 'antd';
import { get } from 'lodash';

import ButtonCustom from 'common/components/button/ButtonCustom';
import { useI18n } from 'common/utils/hookI18n';

const { Panel } = Collapse;

interface IAuthenticationErrorProps {
  readonly error: Error;
}

function AuthenticationError({ error }: IAuthenticationErrorProps) {
  const I18n = useI18n();
  return (
    <Row justify="center">
      <Col span={16}>
        <Result
          status="error"
          title={I18n.t('error.authentication.title')}
          subTitle={I18n.t('error.authentication.description')}
          extra={[
            <ButtonCustom
              key="error-authentication-retry-button"
              type="primary"
              onClick={() => {
                window.location.replace('/');
              }}
              title={I18n.t('error.authentication.retry-button')}
            />,
          ]}
        />
        <Collapse defaultActiveKey={['1']}>
          <Panel header={I18n.t('error.expand-error')} key="1">
            <p>{get(error, 'stack')}</p>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
}

export default AuthenticationError;
