import React from 'react';
import { I18n } from 'react-redux-i18n';
import { VpLeafIcon } from '@vtmn-play/icons/react';

import { generateParams } from 'common/utils/navigation';
import { IModelTable } from 'common/models/interfaces/table';
import ModelToolbar from 'common/models/ModelToolbar';
import ModelFilters from 'common/models/ModelFilters';
import { IPageParams } from 'common/models/interfaces/popup-selector';
import TypeTreeLevel from 'common/models/enums/types/type-tree-level';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
  aggLevel,
) => {
  let link = `/sustainable/${aggLevel?.urlKey}`;

  link += generateParams(filters, table, toolbar, pageParams);

  return link;
};
export default {
  title: () => I18n.t(`navigation.label.sustainablePerformances`),
  id: 'sustainable',
  icon: (
    <VpLeafIcon
      style={{
        height: '28px',
        width: '28px',
        fontSize: '5rem',
        marginLeft: '-2px',
      }}
    />
  ),
  order: 7,
  link: getLinkBySelectors,
  isSpecial: false,
  paths: ['/sustainable/:aggLevel'],
  subLinks: [
    {
      id: 'sustainable-countries',
      title: () => I18n.t(`navigation.label.sustainableByCountries`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.PAYS,
        ),
      paths: ['/sustainable/countries'],
    },
    {
      id: 'sustainable-regions',
      title: () => I18n.t(`navigation.label.sustainableByRegions`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.REGION,
        ),
      paths: ['/sustainable/regions'],
    },
    {
      id: 'sustainable-stores',
      title: () => I18n.t(`navigation.label.sustainableByStores`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.STORE,
        ),
      paths: ['/sustainable/stores'],
    },
    {
      id: 'sustainable-departments',
      title: () => I18n.t(`navigation.label.sustainableByDepartments`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.DEPARTMENT,
        ),
      paths: ['/sustainable/departments'],
    },
  ],
};
