import { i18nReducer, I18nState } from 'react-redux-i18n';
import table from 'common/reducers/table';
import toolbar from 'common/reducers/toolbar';
import filters from 'common/reducers/filters';
import authentication from 'common/reducers/authentication';
import global from 'common/reducers/global';

import ModelToolbar from 'common/models/ModelToolbar';
import ModelFilters from 'common/models/ModelFilters';
import { IStateGlobal } from 'common/constants/initial-state-global';
import { IStateTable } from 'common/constants/initial-state-table';
import { IStateAuthentication } from 'common/constants/initial-state-authentication';

export interface IStateApp {
  table: IStateTable;
  toolbar: ModelToolbar;
  filters: ModelFilters;
  authentication: IStateAuthentication;
  global: IStateGlobal;
  i18n: I18nState;
}

const REDUCERS = {
  table,
  toolbar,
  filters,
  authentication,
  global,
  i18n: i18nReducer,
};

export default REDUCERS;
