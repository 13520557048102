import dayjs, { Dayjs } from 'dayjs';
import { find, isEmpty } from 'lodash';

import ModelTypeTreeLevel from 'common/models/enums/models/type-tree-level';
import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import AbstractModelHierarchy from 'common/models/hierarchies/abstract-hierarchy';
import IApiModelHierarchyParent from 'common/models/hierarchies/api-hierarchy-parent';
import IApiModelHierarchyStore from 'common/models/hierarchies/api-hierarchy-store';
import ModelHierarchyParent from 'common/models/hierarchies/hierarchy-parent';
import ModelHierarchyCountry from 'common/models/hierarchies/ModelHierarchyCountry';
import CallbackString from 'common/models/types/callback-string';

export default class ModelHierarchyStore extends AbstractModelHierarchy {
  declare id: number;

  public readonly parent: ModelHierarchyParent;

  public readonly openingDate: Dayjs;

  public readonly closingDate: Dayjs;

  constructor(
    id: number,
    type: number | ModelTypeTreeLevel,
    label?: string | CallbackString,
    parent?: IApiModelHierarchyParent,
    openingDate?: string,
    closingDate?: string,
  ) {
    super(
      id,
      type instanceof ModelTypeTreeLevel ? type : TypeTreeLevel.byValue(type),
      label,
    );

    this.id = id;
    this.parent = parent
      ? ModelHierarchyParent.fromApiModelHierarchyParent(parent)
      : null;

    this.openingDate = openingDate && dayjs(openingDate, 'YYYY/MM/DD');
    this.closingDate = closingDate && dayjs(closingDate, 'YYYY/MM/DD');
  }

  public get labelWithId(): string {
    const labelWithId = this.constructLabelWithId();
    return (
      (labelWithId && this.type === TypeTreeLevel.ECOMMERCE
        ? `@ ${labelWithId}`
        : labelWithId) || this.idHuman
    );
  }

  public get idHuman(): string {
    return this.type === TypeTreeLevel.ECOMMERCE
      ? `@${this.id}`
      : this.id.toString();
  }

  public static fromApiModelHierarchy(
    data: IApiModelHierarchyStore,
  ): ModelHierarchyStore {
    return new ModelHierarchyStore(
      data.id,
      data.type,
      data.label,
      data.parent,
      data.opening_date,
      data.closing_date,
    );
  }

  public static getHierarchiesFilteredByParents(
    hierarchies: ModelHierarchyStore[],
    hierarchiesParent?: ModelHierarchyCountry[],
  ): AbstractModelHierarchy[] {
    const hierarchiesFiltered = !isEmpty(hierarchiesParent)
      ? hierarchies.filter((hierarchy) =>
          find(hierarchiesParent, (hierarchyParent) =>
            hierarchyParent.isParentOf(hierarchy),
          ),
        )
      : hierarchies;

    return this.getHierarchiesOrdered(hierarchiesFiltered);
  }
}
