import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelTableViewOption from 'common/models/enums/models/ModelTableViewOption';

export default class TypeTableViewOptionEnum extends AbstractEnumType {
  public static OMNI = new ModelTableViewOption('omni', 'omni', null, 2, true);

  public static IN_STORE = new ModelTableViewOption(
    'inStore',
    'in',
    'in_store',
    3,
    true,
  );

  public static OUT_STORE = new ModelTableViewOption(
    'outStore',
    'out',
    'out_store',
    4,
    true,
  );

  public static ALL: ModelTableViewOption[] = this.all(
    TypeTableViewOptionEnum,
    ModelTableViewOption,
  );

  public static byValue(
    searchValue: string,
    defaultValue?: ModelTableViewOption,
  ): ModelTableViewOption {
    return this.by(
      this,
      ModelTableViewOption,
      'value',
      searchValue,
      defaultValue,
    );
  }
}
