import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'oidc-client-ts';

import {
  backToSourceURL,
  cacheUserToken,
  forgetUserToken,
  hasCodeInUrl,
  redirectToFedid,
  resetUser,
  USER_MANAGER,
} from 'common/utils/authentication';
import { useDispatch, useSelector } from 'react-redux';

import { IStateApp } from 'common/reducers';
import * as types from 'common/constants/action-types';
import AuthenticationError from 'core/router/errors/AuthenticationError';

function Authentication({ children }: React.PropsWithChildren) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authentication = useSelector(
    (state: IStateApp) => state.authentication,
  );

  const [isError, setIsError] = useState(false);
  const [error, setError] = useState();

  const setUser = useCallback((user: User) => {
    dispatch({
      type: types.SetUser,
      payload: {
        user,
      },
    });
    // eslint-disable-next-line
  }, []);

  const onUserLoaded = React.useCallback(
    (user: User) => {
      setUser(user);
      cacheUserToken(user);
    },
    [setUser],
  );

  const onUserExpired = React.useCallback(() => {
    resetUser();
    forgetUserToken();
  }, []);

  const signIn = React.useCallback(async () => {
    try {
      if (hasCodeInUrl()) {
        const user = await USER_MANAGER.signinRedirectCallback();
        onUserLoaded(user);
        backToSourceURL(navigate);
      } else {
        const user = await USER_MANAGER.getUser();
        if (user && !user.expired) {
          onUserLoaded(user);
        } else {
          onUserExpired();
          redirectToFedid();
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setIsError(true);
      setError(e);
    }
  }, [onUserLoaded, navigate, onUserExpired]);

  useEffect(() => {
    signIn();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isError && <AuthenticationError error={error} />}
      {!isError &&
        authentication.user &&
        authentication.user.access_token &&
        children}
    </>
  );
}

export default Authentication;
