import React, { Suspense, useEffect } from 'react';
import { map } from 'lodash';
import { Navigate, Route, Routes } from 'react-router-dom';
import Unauthorized from 'core/router/errors/unauthorized';
import Forbidden from 'core/router/errors/forbidden';

import Unavailable from 'core/router/unavailable';
import OverviewNavigation from 'common/utils/navigation/overview';
import LiveNavigation from 'common/utils/navigation/live';
import WorldNavigation from 'common/utils/navigation/world';
import RegionsNavigation from 'common/utils/navigation/regions';
import AllStoresNavigation from 'common/utils/navigation/all-stores';
import ProductsNavigation from 'common/utils/navigation/products';
import TopProductsNavigation from 'common/utils/navigation/top-products';
import CamlNavigation from 'common/utils/navigation/caml';
import FavoritesNavigation from 'common/utils/navigation/favorites';
import TrafficNavigation from 'common/utils/navigation/traffic';
import SustainableNavigation from 'common/utils/navigation/sustainable';
import SustainableStoresFromRegionNavigation from 'common/utils/navigation/sustainableStoresFromRegions';
import StoresFromRegionsNavigation from 'common/utils/navigation/storesFromRegions';

import LogoLoader from 'common/components/loader/logo-loader';
import { useDispatch, useSelector } from 'react-redux';
import * as types from 'common/constants/action-types';
import { IStateApp } from 'common/reducers';
import { useWindowSize } from 'common/hooks/WindowSize';
import { DEVICE_MAX_WIDTH } from 'common/constants/constants';

const World = React.lazy(() => import('pages/world/containers/world'));
const Live = React.lazy(() => import('pages/realtime/containers/realtime'));
const Admin = React.lazy(() => import('pages/admin/containers/Admin'));
const AllStores = React.lazy(
  () => import('pages/all-stores/containers/all-stores'),
);
const AllStoresFromRegion = React.lazy(
  () => import('pages/stores-from-region/containers/all-stores'),
);
const Regions = React.lazy(() => import('pages/regions/containers/Regions'));
const Favorites = React.lazy(
  () => import('pages/favorites/containers/favorites'),
);
const OverviewCompany = React.lazy(
  () => import('pages/overview-company/containers/overview'),
);
const Products = React.lazy(() => import('pages/products/containers/products'));
const CaMl = React.lazy(() => import('pages/caml/containers/caml'));
const TopProducts = React.lazy(
  () => import('pages/top-products/containers/top-products'),
);
const Traffic = React.lazy(() => import('pages/traffic/container/traffic'));
const Profiles = React.lazy(() => import('pages/profiles/containers/Profiles'));
const Sustainable = React.lazy(
  () => import('pages/sustainable/containers/Sustainable'),
);
const SustainableStoresFromRegion = React.lazy(
  () =>
    import(
      'pages/sustainable-stores-from-region/containers/SustainableFromRegions'
    ),
);

function AppRouter() {
  const dispatch = useDispatch();
  const table = useSelector((state: IStateApp) => state.table);
  const window = useWindowSize();

  useEffect(() => {
    if (table.fixHeader && window.width <= DEVICE_MAX_WIDTH) {
      dispatch({
        type: types.UpdateTable,
        payload: {
          table: {
            ...table,
            fixHeader: false,
          },
        },
      });
    }
    if (window.width >= DEVICE_MAX_WIDTH) {
      dispatch({
        type: types.UpdateTable,
        payload: {
          table: {
            ...table,
            fixHeader: true,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, window.width]);

  return (
    <Suspense
      fallback={
        <div className="container-loader">
          <LogoLoader className="center-loader" />
        </div>
      }
    >
      <Routes>
        <Route path="/401" element={<Unauthorized />} />
        <Route path="/403" element={<Forbidden />} />
        <Route path="/unavailable" element={<Unavailable />} />

        {/** Legacy routes * */}
        <Route path="/" element={<Navigate to="/overview" />} />

        <Route path="/admin" element={<Admin />} />
        <Route path="/profiles" element={<Profiles />} />
        {map(OverviewNavigation.paths, (path) => (
          <Route key={path} path={path} element={<OverviewCompany />} />
        ))}
        {map(LiveNavigation.paths, (path) => (
          <Route key={path} path={path} element={<Live />} />
        ))}
        {map(WorldNavigation.paths, (path) => (
          <Route key={path} path={path} element={<World />} />
        ))}
        {map(AllStoresNavigation.paths, (path) => (
          <Route key={path} path={path} element={<AllStores />} />
        ))}
        {map(RegionsNavigation.paths, (path) => (
          <Route key={path} path={path} element={<Regions />} />
        ))}
        {map(ProductsNavigation.paths, (path) => (
          <Route key={path} path={path} element={<Products />} />
        ))}
        {map(TopProductsNavigation.paths, (path) => (
          <Route key={path} path={path} element={<TopProducts />} />
        ))}
        {map(CamlNavigation.paths, (path) => (
          <Route key={path} path={path} element={<CaMl />} />
        ))}
        {map(FavoritesNavigation.paths, (path) => (
          <Route key={path} path={path} element={<Favorites />} />
        ))}
        {map(TrafficNavigation.paths, (path) => (
          <Route key={path} path={path} element={<Traffic />} />
        ))}
        {map(SustainableNavigation.paths, (path) => (
          <Route key={path} path={path} element={<Sustainable />} />
        ))}
        {map(StoresFromRegionsNavigation.paths, (path) => (
          <Route key={path} path={path} element={<AllStoresFromRegion />} />
        ))}
        {map(SustainableStoresFromRegionNavigation.paths, (path) => (
          <Route
            key={path}
            path={path}
            element={<SustainableStoresFromRegion />}
          />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}

export default AppRouter;
