import React, { ReactElement } from 'react';
import { I18n } from 'react-redux-i18n';
import { notification } from 'antd';
import { Interweave } from 'interweave';

import { TIMESTAMP_SECOND } from 'common/constants/time';

import {
  FUNCTIONAL_COLORS,
  NOTIFICATION_ALLOWED_HTML,
} from 'common/constants/constants';
import ButtonCustom from 'common/components/button/ButtonCustom';

type NotificationType = 'info' | 'warning' | 'error' | 'success';

export interface INotifyMessage {
  type: NotificationType;
  title: () => string;
  content: () => string;
  timeout: number;
  onClick?: () => void;
  priority?: boolean;
}

export interface IMessages {
  id: number;
  en: string;
  fr;
  string;
  level: NotificationType;
  start_date: number;
  end_date: number;
  updated_at: number;
}

const defaultNotifyMessage: INotifyMessage = {
  type: 'error',
  title: () => I18n.t('error.global.title'),
  content: () => I18n.t('error.global.message'),
  timeout: TIMESTAMP_SECOND,
};

class Notify {
  public static notify(
    title?: ReactElement | string,
    content?: ReactElement | string,
    timeout = 5, // in seconds
    type: NotificationType = defaultNotifyMessage.type,
    style?: React.CSSProperties,
    key?: string,
    btn?: ReactElement | string,
    hideIcon = false,
  ) {
    const titleOrDefault = !title
      ? I18n.t(`application.admin.messages.${type}`)
      : title;
    const messageOrDefault = !content ? (
      defaultNotifyMessage.content()
    ) : (
      <div style={{ whiteSpace: 'pre-wrap' }}>{content}</div>
    );

    notification[type]({
      message: titleOrDefault,
      description: messageOrDefault,
      duration: timeout, // in seconds
      style,
      btn,
      key,
      ...(hideIcon && { closeIcon: null }),
    });
  }
}

export const displayMessage = (message, locale, duration = 0) => {
  const key = `open${message.id}`;
  let title = null;
  const style = {
    color: 'black',
    borderRadius: '10px',
  };

  if (message.title) {
    title = message.title;
  } else {
    switch (message.level) {
      case 'info':
        title = I18n.t('application.admin.messages.info');
        break;
      case 'warning':
        title = I18n.t('application.admin.messages.warning');
        break;
      case 'error':
        title = I18n.t('application.admin.messages.error');
        break;
      case 'success':
        title = I18n.t('application.admin.messages.success');
        break;
      default:
        title = I18n.t('application.admin.messages.info');
        break;
    }
  }

  Notify.notify(
    <span style={{ color: FUNCTIONAL_COLORS[message.level] }}>{title}</span>,
    <Interweave
      content={message[locale]}
      allowList={NOTIFICATION_ALLOWED_HTML}
    />,
    duration,
    message.level,
    style,
    key,
    <ButtonCustom
      size="small"
      danger={message.level === 'error'}
      type="text"
      style={{ color: FUNCTIONAL_COLORS[message.level] }}
      onClick={() => notification.destroy(key)}
      title={
        <span style={{ color: 'black' }}>
          {I18n.t('application.admin.messages.understand')}
        </span>
      }
    />,
  );
};

export default Notify;
