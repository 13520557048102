import { User } from 'oidc-client-ts';

export interface IStateAuthentication {
  user: User;
}

const INITIAL_STATE_AUTHENTICATION: IStateAuthentication = {
  user: null,
};

export default INITIAL_STATE_AUTHENTICATION;
