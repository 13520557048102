import ModelFilters from 'common/models/ModelFilters';
import DateUtils from 'common/utils/date';
import TypeDateComparableType from 'common/models/enums/types/date-type-comparable';
import TypeDateRange from 'common/models/enums/types/date-range';

const INITIAL_STATE_FILTERS: ModelFilters = new ModelFilters(
  TypeDateRange.YESTERDAY,
  DateUtils.getPeriodByType(
    TypeDateRange.YESTERDAY,
    TypeDateComparableType.DAY_OF_WEEK,
  ),
);

export default INITIAL_STATE_FILTERS;
