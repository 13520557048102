import React from 'react';
import { I18n } from 'react-redux-i18n';
import { VpTrophyIcon } from '@vtmn-play/icons/react';

import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import { generateParams } from 'common/utils/navigation';
import ModelFilters from 'common/models/ModelFilters';
import { IModelTable } from 'common/models/interfaces/table';
import ModelToolbar from 'common/models/ModelToolbar';
import { IPageParams } from 'common/models/interfaces/popup-selector';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
  aggLevel = TypeTreeLevel.FAMILY,
) => {
  let link = `/top/${aggLevel?.urlKey}`;

  link += generateParams(filters, table, toolbar, pageParams);

  return link;
};
export default {
  id: 'top',
  title: (level = null) =>
    level
      ? I18n.t(`navigation.label.top-products`, { level: level && level.label })
      : I18n.t(`navigation.label.top-products-title`),
  icon: <VpTrophyIcon style={{ fontSize: '4rem', marginLeft: '1px' }} />,
  order: 5,
  link: getLinkBySelectors,
  paths: ['/top/:aggLevel'],
  subLinks: [
    {
      id: 'top-families',
      title: () => I18n.t(`navigation.label.top-product.family`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.FAMILY,
        ),
      paths: ['/top/families'],
    },
    {
      id: 'top-models',
      title: () => I18n.t(`navigation.label.top-product.model`),
      link: (filters, table, toolbar, pageParams) =>
        getLinkBySelectors(
          filters,
          table,
          toolbar,
          pageParams,
          TypeTreeLevel.MODEL,
        ),
      paths: ['/top/models'],
    },
  ],
};
