import { I18n } from 'react-redux-i18n';

export default class ModelTableAggregation {
  public readonly value: string;

  public readonly i18nKey: string;

  public readonly isDefault: boolean;

  constructor(value: string, i18nKey: string, isDefault = false) {
    this.value = value;
    this.i18nKey = i18nKey;
    this.isDefault = isDefault;
  }

  public get label(): string {
    return I18n.t(`application.${this.i18nKey}`);
  }
}
