import IModelHeader from 'common/models/interfaces/header';

const INITIAL_STATE_HEADER: IModelHeader = {
  disabledFlag: false,
  propsPopupSelector: {
    withWorld: true,
    withCountries: true,
    withFavoriteCountries: false,
    withRegions: false,
    withStores: false,
    withDepartments: false,
    withSubDepartments: false,
    withFavoriteSubDepartments: false,
    withFamilies: false,
    withFavoriteFamilies: false,
    withFavoriteStores: false,
    withFavoriteDepartments: false,
    withComparableCalendar: false,
  },
  liveDate: false,
  showFilters: true,
  infoBubbleFilters: {},
};
export default INITIAL_STATE_HEADER;
