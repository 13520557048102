import React from 'react';
import { noop } from 'lodash';
import { Result } from 'antd';
import PropTypes from 'prop-types';

import ButtonCustom from 'common/components/button/ButtonCustom';
import { useI18n } from 'common/utils/hookI18n';

function NetworkError({ onRetry }) {
  const I18n = useI18n();
  return (
    <Result
      status="error"
      title={I18n.t('error.network.title')}
      subTitle={I18n.t('error.network.description')}
      extra={[
        <ButtonCustom
          key="error-network-retry-button"
          type="primary"
          onClick={() => onRetry()}
          style={{ background: '#3643BA' }}
          title={I18n.t('error.network.retry-button')}
        />,
      ]}
    />
  );
}

NetworkError.propTypes = {
  onRetry: PropTypes.func,
};

NetworkError.defaultProps = {
  onRetry: noop,
};

export default NetworkError;
