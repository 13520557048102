export function reducerFactory<T>(initialState: T, handlers) {
  return (state: T, action): T => {
    const handler = handlers[action.type];

    if (handler) {
      return handler(state || initialState, action);
    }

    return state || initialState;
  };
}
