import Optional from 'common/models/types/optional';
import { filter } from 'lodash';

export default class AbstractEnumType {
  protected static by<
    ReturnConstructorClass,
    SearchValue,
    TypeClass,
    ReturnClass,
  >(
    self: TypeClass,
    classReturn: ReturnConstructorClass,
    param: string,
    searchValue: SearchValue,
    defaultValue: Optional<ReturnClass> = null,
  ): Optional<ReturnClass> {
    if (searchValue === undefined || searchValue === null) {
      return defaultValue;
    }

    const foundEnumItem = this.all<ReturnClass>(self, classReturn).find(
      (item) => item[param] === searchValue,
    );

    return foundEnumItem || defaultValue;
  }

  protected static all<ReturnClass>(self, clazz): ReturnClass[] {
    return filter(self, (item) => item instanceof clazz);
  }
}
