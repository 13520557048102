import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import AbstractModelHierarchy from 'common/models/hierarchies/abstract-hierarchy';
import IApiModelHierarchyParent from 'common/models/hierarchies/api-hierarchy-parent';

export default class ModelHierarchyParent extends AbstractModelHierarchy {
  public static fromApiModelHierarchyParent(
    data: IApiModelHierarchyParent,
  ): ModelHierarchyParent {
    const type = TypeTreeLevel.byValue(data.type);
    const id = type === TypeTreeLevel.PAYS ? data.id : parseInt(data.id, 10);

    return new ModelHierarchyParent(id, type, null);
  }
}
