import { reducerFactory } from 'common/utils/reducer';

import { IAction } from 'common/models/action';

import * as types from 'common/constants/action-types';
import INITIAL_STATE_AUTHENTICATION, {
  IStateAuthentication,
} from 'common/constants/initial-state-authentication';

const handlers = {};

handlers[types.SetUser] = (state, action: IAction<types.SetUserType>) => {
  return {
    ...state,
    user: action.payload.user,
  };
};

handlers[types.ResetUser] = (state) => {
  return {
    ...state,
    user: null,
  };
};

export default reducerFactory<IStateAuthentication>(
  INITIAL_STATE_AUTHENTICATION,
  handlers,
);
