export default class NumberUtils {
  public static round(x: number, decimal = 0): number {
    return Math.round(x * 10 ** decimal) / 10 ** decimal;
  }

  public static formatThousands(x: number, decimal = 0): string {
    const xRounded = NumberUtils.round(x, decimal);
    const pattern = /\B(?=(\d{3})*(?!\d))/g;
    return typeof xRounded !== 'number' || Number.isNaN(xRounded)
      ? ''
      : xRounded.toString().replace(pattern, ' ');
  }

  public static priceShortFormat(
    price: number,
    unit: string = null,
    digits = 0,
  ): string {
    const priceRounded = NumberUtils.round(price, digits);

    const symbols = ['K', 'M', 'Mds'];

    let decimal;
    for (let i = symbols.length - 1; i >= 0; i -= 1) {
      decimal = 1000 ** (i + 1);

      if (priceRounded <= -decimal || priceRounded >= decimal) {
        const amount = priceRounded / decimal;
        const symbol = symbols[i] + (unit != null ? unit : '');
        return `${amount} ${symbol}`;
      }
    }

    return priceRounded + (unit != null ? ` ${unit}` : '');
  }

  public static leadingZeroDate(date: number): string {
    if (typeof date !== 'number' || Number.isNaN(date)) {
      return '';
    }
    return (date < 10 && date > -10 ? '0' : '') + Math.abs(date).toString();
  }

  public static sign(x: number): number {
    if (typeof x !== 'number' || Number.isNaN(x)) {
      return NaN;
    }
    return x < 0 ? -1 : 1;
  }
}
