import React from 'react';
import { I18n } from 'react-redux-i18n';

import { VpEarthIcon } from '@vtmn-play/icons/react';
import { generateParams } from 'common/utils/navigation';
import ModelFilters from 'common/models/ModelFilters';
import ModelToolbar from 'common/models/ModelToolbar';
import { IModelTable } from 'common/models/interfaces/table';
import { IPageParams } from 'common/models/interfaces/popup-selector';

const getLinkBySelectors = (
  filters: ModelFilters,
  table: IModelTable,
  toolbar: ModelToolbar,
  pageParams: IPageParams,
) => {
  let link = '/world';

  link += generateParams(filters, table, toolbar, pageParams);

  return link;
};

export default {
  id: 'world',
  title: () => I18n.t(`navigation.label.world`),
  icon: <VpEarthIcon style={{ fontSize: '3rem', marginLeft: '-3px' }} />,
  order: 2,
  link: getLinkBySelectors,
  paths: ['/world'],
  subLinks: null,
};
