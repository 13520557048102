import { find, isEmpty, isEqual } from 'lodash';

import TypeTreeLevel from 'common/models/enums/types/type-tree-level';
import IApiModelProductHierarchy from 'common/models/hierarchies/products/api-product-hierarchy-data';
import AbstractModelProductHierarchy from 'common/models/hierarchies/products/abstract-product-hierarchy';
import ModelProductHierarchyParent from 'common/models/hierarchies/products/product-hierarchy-parent';

export default class ModelProductHierarchy extends AbstractModelProductHierarchy {
  public readonly parent: ModelProductHierarchyParent;

  constructor(data: IApiModelProductHierarchy) {
    super(data.id, data.level, data.label);

    this.parent =
      data.parent && !isEqual(TypeTreeLevel.DEPARTMENT, this.level)
        ? ModelProductHierarchyParent.fromApiModelProductHierarchyParent(
            data.parent,
          )
        : null;
  }

  public static fromApiModelProductHierarchy(
    data: IApiModelProductHierarchy,
  ): ModelProductHierarchy {
    return new ModelProductHierarchy(data);
  }

  public isParentOf(productHierarchy: ModelProductHierarchy): boolean {
    return productHierarchy && this.isEqual(productHierarchy.parent);
  }

  public static getHierarchiesFilteredByParents(
    hierarchies: ModelProductHierarchy[],
    hierarchiesParent: ModelProductHierarchy[] = null,
  ): ModelProductHierarchy[] {
    const hierarchiesFiltered = !isEmpty(hierarchiesParent)
      ? hierarchies.filter((hierarchy) =>
          find(hierarchiesParent, (hierarchyParent) =>
            hierarchyParent?.isParentOf(hierarchy),
          ),
        )
      : hierarchies;

    return this.getHierarchiesOrdered(hierarchiesFiltered);
  }
}
