import React from 'react';

import ErrorPage from 'core/router/errors/error-page';

function Forbidden() {
  return (
    <ErrorPage
      title="error.http.forbidden.title"
      message="error.http.forbidden.message"
    />
  );
}

export default Forbidden;
