import { I18n } from 'react-redux-i18n';
import ModelUnitEnum from 'common/models/enums/models/unit-enum';
import Optional from 'common/models/types/optional';

export default class ModelKpi {
  public readonly value: string;

  public readonly urlParam: string;

  private readonly keyTranslate: string;

  private readonly groupKeyTranslate: string;

  private readonly weight: number;

  public readonly unitVolume: ModelUnitEnum;

  public readonly unitProgression: ModelUnitEnum;

  public readonly showColumnsShareAmount: boolean;

  public readonly precision: number;

  public readonly ratePrecision: number;

  constructor(
    value: string,
    urlParam: string,
    keyTranslate: string,
    groupKeyTranslate: Optional<string>,
    weight: number,
    unitVolume: ModelUnitEnum,
    unitProgression = null,
    showColumnsShareAmount = true,
    precision = 0,
    ratePrecision = 2,
  ) {
    this.value = value;
    this.urlParam = urlParam || value;
    this.keyTranslate = keyTranslate;
    this.groupKeyTranslate = groupKeyTranslate;
    this.weight = weight;
    this.unitVolume = unitVolume;
    this.unitProgression = unitProgression || unitVolume;
    this.showColumnsShareAmount = showColumnsShareAmount;
    this.precision = precision;
    this.ratePrecision = ratePrecision;
  }

  public toString(): string {
    return this.label;
  }

  public get label(): string {
    return this.groupKeyTranslate
      ? I18n.t(
          `application.filter.${this.groupKeyTranslate}.${this.keyTranslate}`,
        )
      : null;
  }

  public get labelWithOf(): string {
    return this.groupKeyTranslate
      ? I18n.t(
          `application.filter.${this.groupKeyTranslate}.with-of.${this.keyTranslate}`,
        )
      : null;
  }

  public get titleTable(): string {
    return I18n.t(`application.table.title.${this.keyTranslate}`);
  }

  public get titleSalesSupportGraphOverview(): string {
    return I18n.t(
      `application.overview.chart.sales-support.${this.keyTranslate}`,
    );
  }

  public get titleHistogramGraphOverview(): string {
    return I18n.t('application.overview.chart.histogram', {
      salesSupportLabel: this.labelWithOf,
    });
  }

  public get titleHistrogramGraphOverviewAvgOrder(): string {
    return I18n.t(`application.overview.chart.histogram-${this.keyTranslate}`);
  }

  public get titleHistrogramGraphOverviewTicket(): string {
    return I18n.t(`application.overview.chart.histogram-${this.keyTranslate}`);
  }
}
