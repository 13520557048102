import ModelFilters from 'common/models/ModelFilters';

type CallbackSymbol = (dataKpi, filters: ModelFilters) => string;

export default class ModelUnitEnum {
  public readonly value: string;

  public readonly decimal: number;

  public readonly shortDecimal: number;

  private readonly symbol: string | CallbackSymbol;

  constructor(
    value: string,
    decimal = 0,
    shortDecimal?: number,
    symbol?: string | CallbackSymbol,
  ) {
    this.value = value;
    this.decimal = decimal;
    this.shortDecimal = shortDecimal || decimal;
    this.symbol = symbol;
  }

  public getSymbol(
    dataKpi,
    filters: ModelFilters,
    includeSpaceIfNeeded = false,
  ): string {
    if (!this.symbol) {
      return '';
    }

    const symbol =
      typeof this.symbol === 'string'
        ? this.symbol
        : this.symbol(dataKpi, filters);
    return `${includeSpaceIfNeeded ? ' ' : ''}${symbol}`;
  }
}
