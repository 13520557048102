import IModelHeader from 'common/models/interfaces/header';
import ModelProductHierarchy from 'common/models/hierarchies/products/product-hierarchy';
import IApiUserData from 'common/models/api-user-data';

import INITIAL_STATE_HEADER from 'common/constants/initial-state-header';
import Admin from 'common/models/admin';
import { IMessages } from 'common/utils/notify';
import ModelHierarchyCountry from 'common/models/hierarchies/ModelHierarchyCountry';
import ModelHierarchyStore from 'common/models/hierarchies/ModelHierarchyStore';
import { IProfiles } from 'common/models/interfaces/profiles';

export interface IStateGlobal {
  header: IModelHeader;
  userInfo: IApiUserData;
  stores: ModelHierarchyStore[];
  countries: ModelHierarchyCountry[];
  departments: ModelProductHierarchy[];
  subDepartments: ModelProductHierarchy[];
  families: ModelProductHierarchy[];
  models: ModelProductHierarchy[];
  messages: IMessages[];
  profiles: IProfiles;
  admin: Admin;
}

const APP: IStateGlobal = {
  header: INITIAL_STATE_HEADER,
  userInfo: null,
  stores: [],
  countries: [],
  departments: [],
  subDepartments: [],
  families: [],
  models: [],
  profiles: {},
  messages: [],
  admin: null,
};

export default APP;
