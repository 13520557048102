import { User } from 'oidc-client-ts';

import IApiInitDatas from 'common/models/api-init-data';
import IModelHeader from 'common/models/interfaces/header';
import ModelProductHierarchy from 'common/models/hierarchies/products/product-hierarchy';
import { IModelTable } from 'common/models/interfaces/table';
import ModelHierarchyCountry from 'common/models/hierarchies/api-hierarchy-country';
import ModelHierarchyStore from 'common/models/hierarchies/api-hierarchy-store';
import { IProfile, IProfileContent } from 'common/models/interfaces/profiles';
import ModelFilters from 'common/models/ModelFilters';
import ModelToolbar from 'common/models/ModelToolbar';

export const SetUser = 'SET_USER';
export type SetUserType = { user: User };

export const ResetUser = 'RESET_USER';

export const ChangeHeader = 'CHANGE_HEADER';
export type ChangeHeaderType = IModelHeader;

export const InitApp = 'INIT_APP';
export type InitAppType = IApiInitDatas;

export const UpdateFilters = 'UPDATE_FILTERS';
export type UpdateFiltersType = ModelFilters;

export const FindHierarchies = 'FIND_HIERARCHIES';
export type FindHierarchiesType = {
  countries: ModelHierarchyCountry[];
  stores: ModelHierarchyStore[];
  departments: ModelProductHierarchy[];
  subDepartments: ModelProductHierarchy[];
  families: ModelProductHierarchy[];
  models: ModelProductHierarchy[];
};

export const GetProfilesSuccess = 'GET_PROFILES_SUCCESS';
export type GetProfilesSuccessType = { profiles: IProfile[]; loading: boolean };

export const LoadProfile = 'LOAD_PROFILE';
export type LoadProfileType = { profile: number; content: IProfileContent };

export const SetProfilesDefault = 'SET_PROFILES_DEFAULT';
export type SetProfilesDefaultType = { profile: number };

export const DuplicateProfile = 'DUPLICATE_PROFILE';
export type DuplicateProfileType = { profiles: IProfile[] };

export const DeleteProfile = 'DELETE_PROFILE';
export type DeleteProfileType = { profile: number };

export const EditProfile = 'EDIT_PROFILE';
export type EditProfileType = { profile: IProfile };

export const InitializeTableOptions = 'INITIALIZE_TABLE_OPTIONS';
export type InitializeTableOptionsType = {
  table: IModelTable;
};

export const UpdateTable = 'UPDATE_TABLE';
export type UpdateTableType = {
  table: IModelTable;
};

export const UpdateToolbar = 'UPDATE_TOOLBAR';
export const UpdateToolbarType = ModelToolbar;
