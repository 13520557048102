import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelCurrencyFilter from 'common/models/enums/models/currency-filter';
import Optional from 'common/models/types/optional';

export const EURO_DEVISE = 'EUR';

export default class TypeCurrencyFilter extends AbstractEnumType {
  public static EURO = new ModelCurrencyFilter(
    'euro',
    'EUR',
    'application.filter.currency.euro',
    'fa fa-eur',
  );

  public static LOCAL = new ModelCurrencyFilter(
    'locale',
    null,
    'application.filter.currency.local',
    'fa fa-money-bill',
  );

  public static ALL: ModelCurrencyFilter[] = this.all(
    TypeCurrencyFilter,
    ModelCurrencyFilter,
  );

  public static byValue(
    searchValue: string,
    defaultValue?: ModelCurrencyFilter,
  ): Optional<ModelCurrencyFilter> {
    return this.by(
      this,
      ModelCurrencyFilter,
      'value',
      searchValue,
      defaultValue,
    );
  }
}
