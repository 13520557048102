import dayjs, { Dayjs } from 'dayjs';

import TypeDateRange from 'common/models/enums/types/date-range';

interface IDateObject {
  to: Dayjs;
  from: Dayjs;
}

export const getFromAndToFromUrl = (
  fromUrl: string,
  toUrl: string,
): IDateObject => {
  const isValidFromUrl = dayjs(fromUrl, 'YYYYMMDD').isValid();
  const isValidToUrl = dayjs(toUrl, 'YYYYMMDD').isValid();
  const from = isValidFromUrl
    ? dayjs(fromUrl)
    : dayjs(TypeDateRange.YESTERDAY.from);
  const to = isValidToUrl ? dayjs(toUrl) : from;

  return {
    from: from.isBefore(to) ? from : to,
    to: from.isAfter(to) ? from : to,
  };
};
