function ApiRetryException(
  type: number,
  content: any = null,
  message: string = null,
) {
  this.type = type;
  this.content = content;
  this.message = message;
}

export default ApiRetryException;
