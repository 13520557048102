import DateUtils from 'common/utils/date';
import { find } from 'lodash';

import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelDateRange from 'common/models/enums/models/date-range';
import { Dayjs } from 'dayjs';
import TypeDateComparableType from 'common/models/enums/types/date-type-comparable';

export default class TypeDateRange extends AbstractEnumType {
  public static TODAY = new ModelDateRange(
    'today',
    'application.filter.date-range.today',
    'application.filter.date-range.today',
    (from = DateUtils.getToday()) => from,
    (from = DateUtils.getToday()) => from,
  );

  public static YESTERDAY = new ModelDateRange(
    'yesterday',
    'application.filter.date-range.day-minus-one',
    'application.filter.date-range.short.day-minus-one',
    (from = DateUtils.getYesterday()) => from,
    (from = DateUtils.getYesterday()) => from,
    TypeDateComparableType.DAY_OF_WEEK,
    1,
  );

  public static LAST_SATURDAY = new ModelDateRange(
    'lastSaturday',
    'application.filter.date-range.last-saturday',
    'application.filter.date-range.short.last-saturday',
    (from = DateUtils.getToday()) => DateUtils.getDateLastSaturday(from),
    (from = DateUtils.getToday()) => DateUtils.getDateLastSaturday(from),
    TypeDateComparableType.DAY_OF_WEEK,
  );

  public static WEEK_TO_DATE = new ModelDateRange(
    'weekToDate',
    'application.filter.date-range.since-beginning-week',
    'application.filter.date-range.short.since-beginning-week',
    () => DateUtils.getDateBeginningWeek(),
    (from) => DateUtils.getFromDateOrDefaultYesterday(from),
    TypeDateComparableType.DAY_OF_WEEK,
    3,
  );

  public static WEEK_ONE = new ModelDateRange(
    'lastWeek',
    'application.filter.date-range.week-minus-one',
    'application.filter.date-range.short.week-minus-one',
    (from = DateUtils.getToday()) => DateUtils.getDateLastSunday(from),
    (from = DateUtils.getToday()) => DateUtils.getDateLastSaturday(from),
    TypeDateComparableType.DAY_OF_WEEK,
    4,
  );

  public static MONTH_TO_DATE = new ModelDateRange(
    'monthToDate',
    'application.filter.date-range.since-beginning-month',
    'application.filter.date-range.short.since-beginning-month',
    (from = DateUtils.getYesterday()) =>
      DateUtils.getDateBeginningMonth(0, from),
    (from) => DateUtils.getFromDateOrDefaultYesterday(from),
    TypeDateComparableType.CALENDAR_DATE,
    5,
  );

  public static MONTH_ONE = new ModelDateRange(
    'lastMonth',
    'application.filter.date-range.month-minus-one',
    'application.filter.date-range.short.month-minus-one',
    (from = DateUtils.getYesterday()) =>
      DateUtils.getDateBeginningMonth(1, from),
    (from = DateUtils.getYesterday()) => DateUtils.getDateEndMonth(1, from),
    TypeDateComparableType.CALENDAR_DATE,
    6,
  );

  public static FOUR_S = new ModelDateRange(
    '4s',
    'application.filter.date-range.4s',
    'application.filter.date-range.short.4s-short',
    (from = DateUtils.getYesterday()) => DateUtils.get4sStart(from),
    (from) => DateUtils.getDateLastSaturday(from),
    TypeDateComparableType.DAY_OF_WEEK,
  );

  public static YEAR_TO_DATE = new ModelDateRange(
    'yearToDate',
    'application.filter.date-range.since-beginning-year',
    'application.filter.date-range.short.since-beginning-year',
    (from = DateUtils.getYesterday()) => DateUtils.getDateBeginningYear(from),
    (from) => DateUtils.getFromDateOrDefaultYesterday(from),
    TypeDateComparableType.CALENDAR_DATE,
    7,
  );

  public static YEAR_SLIDING = new ModelDateRange(
    'yearSliding',
    'application.filter.date-range.year-sliding',
    'application.filter.date-range.short.year-sliding',
    (from = DateUtils.getYesterday()) => DateUtils.getDateMinusWeek(52, from),
    (from) => DateUtils.getFromDateOrDefaultYesterday(from),
    TypeDateComparableType.CALENDAR_DATE,
    8,
  );

  public static TWELVE_MONTHS_BEFORE = new ModelDateRange(
    'twelveMonthsBefore',
    'application.filter.date-range.twelve-months',
    'application.filter.date-range.short.twelve-months',
    () => DateUtils.getFirstDayOfCurrentMonthMinusOneYear(),
    (from = DateUtils.getYesterday()) =>
      DateUtils.getLastDayFromPreviousMonth(from),
    TypeDateComparableType.CALENDAR_DATE,
  );

  public static ALL: ModelDateRange[] = this.all(TypeDateRange, ModelDateRange);

  public static get CUSTOM_DATE(): ModelDateRange {
    return this.getCustomDate(DateUtils.getYesterday());
  }

  public static getCustomDate(from: Dayjs, to?: Dayjs): ModelDateRange {
    return new ModelDateRange(
      'customDate',
      'application.filter.date-range.custom-date',
      'application.filter.date-range.short.custom-date',
      () => from,
      () => to,
      TypeDateComparableType.CALENDAR_DATE,
      null,
      null,
      true,
    );
  }

  public static byValue(
    searchValue: string,
    defaultValue?: ModelDateRange,
  ): ModelDateRange | null {
    return this.by(this, ModelDateRange, 'value', searchValue, defaultValue);
  }

  public static byLocalStorageValue(
    searchLocalStorageValue: string,
    defaultValue?: ModelDateRange | null,
  ): ModelDateRange | null {
    if (searchLocalStorageValue) {
      const searchLocalStorageValueParts = searchLocalStorageValue.split(':');

      return (
        this.byValue(searchLocalStorageValueParts[0]) ||
        this.getCustomDate(
          DateUtils.fromApiDateString(searchLocalStorageValueParts[1]),
          DateUtils.fromApiDateString(searchLocalStorageValueParts[2]),
        )
      );
    }

    return defaultValue;
  }

  public static byFromAndTo(from: Dayjs, to: Dayjs): ModelDateRange {
    return find(this.ALL, {
      from,
      to,
    });
  }
}
