import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import TypeCurrencyFilter, {
  EURO_DEVISE,
} from 'common/models/enums/types/currency-filter';
import ModelUnitEnum from 'common/models/enums/models/unit-enum';
import getCurrencySymbolForIsoCode from 'common/constants/currency';
import { isEqual } from 'lodash';

export default class TypeUnitEnum extends AbstractEnumType {
  public static NONE = new ModelUnitEnum('none', 0, 1);

  public static CURRENCY = new ModelUnitEnum(
    'currency',
    0,
    1,
    (dataKpi, filters) => {
      const isoCode = isEqual(filters.currency, TypeCurrencyFilter.LOCAL)
        ? dataKpi?.currency || dataKpi?.currencyRate?.currency || EURO_DEVISE
        : EURO_DEVISE;

      return getCurrencySymbolForIsoCode(isoCode);
    },
  );

  public static PERCENTAGE = new ModelUnitEnum('percentage', 2, 2, '%');

  public static POINTS = new ModelUnitEnum('points', 2, 2, 'pts');

  public static byValue(searchValue: string): ModelUnitEnum {
    return this.by(this, ModelUnitEnum, 'value', searchValue);
  }
}
