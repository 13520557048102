import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelStatusSearchData from 'common/models/enums/models/status-search-data';

export default class TypeStatusSearchData extends AbstractEnumType {
  public static FOUND = new ModelStatusSearchData(
    'found',
    'application.label.status.no-data',
  );

  public static WAITING = new ModelStatusSearchData(
    'waiting',
    'application.label.status.waiting',
  );

  public static ERROR = new ModelStatusSearchData(
    'error',
    'application.label.status.error',
  );

  public static byValue(searchValue: string): ModelStatusSearchData {
    return this.by(this, ModelStatusSearchData, 'value', searchValue);
  }
}
