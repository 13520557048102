import { I18n } from 'react-redux-i18n';

export default class ModelDateTypeComparable {
  public readonly value: string;

  private readonly keyI18n: string;

  constructor(value: string, label: string) {
    this.value = value;
    this.keyI18n = label;
  }

  public get label(): string {
    return I18n.t(this.keyI18n);
  }
}
