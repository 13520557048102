import { I18n } from 'react-redux-i18n';

export default class ModelCurrencyFilter {
  public readonly value: string;

  public readonly iso: string;

  private readonly keyI18n: string;

  public readonly icon: string;

  constructor(value: string, iso: string, keyI18n: string, icon: string) {
    this.value = value;
    this.iso = iso;
    this.keyI18n = keyI18n;
    this.icon = icon;
  }

  public toString(): string {
    return this.label;
  }

  public get id(): string {
    return this.value;
  }

  public get label(): string {
    return I18n.t(this.keyI18n);
  }
}
