import { reducerFactory } from 'common/utils/reducer';

import INITIAL_STATE, {
  IStateTable,
} from 'common/constants/initial-state-table';
import {
  InitializeTableOptions,
  InitializeTableOptionsType,
  UpdateTable,
  UpdateTableType,
} from 'common/constants/action-types';
import { convertModelTableToLocalStorage } from 'common/utils/table';
import { IAction } from '../models/action';
import LocalStorageUtils from '../utils/local-storage';
import { USER_TABLE_OPTIONS } from '../constants/cookies-keys';
import { TIME_WEEK } from '../constants/time';

const tableReducer = (state, newTable) => {
  const newStateTable = {
    ...state,
    ...newTable,
  };

  LocalStorageUtils.setItem(
    USER_TABLE_OPTIONS,
    JSON.stringify(convertModelTableToLocalStorage(newStateTable)),
    TIME_WEEK,
  );

  return newStateTable;
};

const handlers = {};

handlers[InitializeTableOptions] = (
  state: IStateTable,
  action: IAction<InitializeTableOptionsType>,
) => {
  return tableReducer(state, action.payload.table);
};

handlers[UpdateTable] = (
  state: IStateTable,
  action: IAction<UpdateTableType>,
) => {
  return tableReducer(state, action.payload.table);
};

export default reducerFactory(INITIAL_STATE, handlers);
