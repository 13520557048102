import { I18n } from 'react-redux-i18n';

export default class ModelStatusSearchData {
  public readonly value: string;

  private readonly keyI18n: string;

  public readonly typeGraph: string;

  constructor(value: string, keyI18n: string, typeGraph?: string) {
    this.value = value;
    this.keyI18n = keyI18n;
    this.typeGraph = typeGraph;
  }

  public toString(): string {
    return this.label;
  }

  public get label(): string {
    return I18n.t(this.keyI18n);
  }
}
